<template>
  <div class="customer_sec">
    <div class="flex">
      <div class="left_section display"></div>

      <div class="customer_signup">
        <div class="right_sec remove_center_align">
          <div class="form_head">
            <img
              src="../../assets/images/visitormatch-logo.png"
              alt="logo"
              width="145"
            />
            <h1 class="sign_header">Reset Password</h1>
            <form data-vv-scope="forgotPasswordForm" @submit.prevent>
              <ul class="input_listing c_header_Sec">
                <li v-if="isLinkSent">
                  <div class="mail_redirect">
                    <p>
                      If the entered email is correct, you will receive a new
                      password in the email. Please ensure to check the spam
                      folder as well. <br /><br />
                      If you don't have access to that email box, you can
                      contact
                      <a
                        href="mailto:joerare@gmail.com"
                        class="link_redirect font-bold"
                        >joerare@gmail.com</a
                      >
                    </p>
                  </div>
                </li>
                <li v-if="!isLinkSent">
                  <div class="input_sec">
                    <input
                      :class="
                        isErrorExist(errors, 'email')
                          ? 'error-customer-field'
                          : ''
                      "
                      name="email"
                      type="text"
                      placeholder="Your email address"
                      class="w-full"
                      v-model="email"
                      v-validate="'required|email'"
                    />
                    <b-icon
                      v-show="isErrorExist(errors, 'email')"
                      icon="exclamation-circle-fill"
                      font-scale="1.5"
                      class="text-white"
                    ></b-icon>
                  </div>

                  <span class="error_msg mb-0">{{
                    errors.first("forgotPasswordForm.email")
                  }}</span>
                </li>
              </ul>
              <button
                v-if="!isLinkSent"
                class="signin pass_btn"
                @click="forgotPassword()"
              >
                <span class="d-flex align-center gap-2">
                  <img
                    v-show="isLoader"
                    src="../../assets/images/loadar.svg"
                    width="20px"
                    alt="loader"
                    class="animate-spin"
                  />Reset Password</span
                >
              </button>
              <button
                v-if="isLinkSent"
                class="signin pass_btn"
                @click="redirectToLogin()"
              >
                <span class="d-flex align-center gap-2">Proceed to Login</span>
              </button>
            </form>
            <!-- <div class="text-center mt-5">
              <router-link :to="{name: 'page-login'}" class="link_redirect font-bold">Return to Login</router-link>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from "vee-validate";

const dict = {
  custom: {
    email: {
      required: "The email field is required",
      email: "This e-mail address is not valid.",
    },
  },
};

Validator.localize("en", dict);
export default {
  data() {
    return {
      email: null,
      isLoader: false,
      isLinkSent: false,
    };
  },
  methods: {
    isErrorExist(e, field) {
      let errorObj = e.items.find((o) => o.field === field);
      if (errorObj) {
        return true;
      }
    },
    forgotPassword() {
      this.$validator.validateAll("forgotPasswordForm").then((result) => {
        if (result) {
          // this.$vs.loading();
          this.isLoader = true;
          let url =
            "Account/ForgotPasswordVue?Email=" + encodeURIComponent(this.email);
          this.axios
            .post(url)
            .then(() => {
              // this.$vs.loading.close();
              this.isLinkSent = true;
              this.isLoader = false;
              // this.$vs.notify({
              //   title: "Success",
              //   text: response.data,
              //   color: "success",
              //   iconPack: "feather",
              //   icon: "icon-check",
              //   position: "top-right",
              //   time: 4000,
              // });
            })
            .catch(() => {
              // console.log(e);
              // this.$vs.loading.close();
              this.isLoader = false;
            });
        }
      });
    },
    redirectToLogin() {
      this.$router.push({
        name: "page-login",
      });
    },
  },
};
</script>

<style></style>
